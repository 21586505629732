<template>
  <div>
    <b-form>
      <b-form-group label="Nama Lengkap" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('namaPelamar')"
          v-model="$v.dataProfil.namaPelamar.$model"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tanggal Masuk Lamaran" label-cols-md="3">
        <date-picker
          style="width: 100%"
          v-model="dataProfil.tanggalMasukLamaran"
          disabled
          :placeholder="moment().format('YYYY/MM/DD')"
        ></date-picker>
      </b-form-group>

      <b-form-group label="No. KTP" label-cols-md="3">
        <b-form-input
          type="text"
          disabled
          :state="checkIfValid('nomorKTPPelamar')"
          v-model="$v.dataProfil.nomorKTPPelamar.$model"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group label="Divisi Yang Dilamar" label-cols-md="3">
        <multiselect
          :disabled="false"
          :options="divisi"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          placeholder="-- Pilih Divisi --"
        ></multiselect>
      </b-form-group> -->

      <b-form-group label="Posisi Yang Dilamar" label-cols-md="3">
        <b-form-input
          type="text"
          disabled
          :state="checkIfValid('posisiLamaran')"
          v-model="$v.dataProfil.posisiLamaran.$model"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tinggi Badan" label-cols-md="3">
        <b-form-input
          type="text"
          v-model="dataProfil.tinggiBadanPelamar"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Berat Badan" label-cols-md="3">
        <b-form-input
          type="text"
          v-model="dataProfil.beratBadanPelamar"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Agama" label-cols-md="3">
        <b-form-select
          :options="agama"
          type="text"
          v-model="dataProfil.agamaPelamar"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Kebangsaan" label-cols-md="3">
        <b-form-select
          :options="kebangsaan"
          v-model="dataProfil.kebangsaanPelamar"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Jenis Kelamin" label-cols-md="3">
        <b-form-select
          :options="jenisKelamin"
          v-model="dataProfil.jenisKelaminPelamar"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Status Pernikahan" label-cols-md="3">
        <b-form-select
          :options="statusPerkawinan"
          v-model="dataProfil.statusPelamar"
        ></b-form-select>
      </b-form-group>

      <!-- <b-form-group label="Pendidikan Terakhir" label-cols-md="3">
        <b-form-select v-model="data.kebangsaanPelamar" :options="pendidikanTerakhir"></b-form-select>
      </b-form-group> -->

      <b-form-group label="Tempat Lahir" label-cols-md="3">
        <b-form-input
          type="text"
          v-model="dataProfil.tempatLahirPelamar"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tanggal Lahir" label-cols-md="3">
        <date-picker
        disabled
          style="width: 100%"
          v-model="dataProfil.tanggalLahirPelamar"
          :placeholder="dataProfil.tglLahir"
        ></date-picker>
      </b-form-group>

      <b-form-group label="No. Telepon/Hp" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('noHpPelamar')"
          v-model="$v.dataProfil.noHpPelamar.$model"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Email" label-cols-md="3">
        <b-form-input
          disabled
          :state="checkIfValid('emailPelamar')"
          v-model="$v.dataProfil.emailPelamar.$model"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Alamat" label-cols-md="3">
        <b-form-input
          :state="checkIfValid('alamatPelamar')"
          v-model="$v.dataProfil.alamatPelamar.$model"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group
        label="Keterangan Lain/Alasan Menolak Lamaran"
        label-cols-md="3"
      >
        <b-form-textarea id="textarea" rows="3" max-rows="6"></b-form-textarea>
      </b-form-group> -->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "inputTabProfil",
  props: ["dataProfil"],
  components: {
    DatePicker,
    Multiselect,
  },

  data() {
    return {
      moment,
      agama: ["Islam", "Kristen", "Katolik", "Hindu", "Budha", "Lainnya"],
      kebangsaan: [
        { value: "WNI", text: "WNI" },
        { value: "WNA", text: "WNA" },
      ],
      jenisKelamin: [
        { value: "Laki-laki", text: "Laki-laki" },
        { value: "Perempuan", text: "Perempuan" },
      ],
      statusPerkawinan: [
        { value: "Menikah", text: "Menikah" },
        { value: "Belum Menikah", text: "Belum Menikah" },
        { value: "Cerai Hidup", text: "Cerai Hidup" },
        { value: "Cerai Mati", text: "Cerai Mati" },
      ],
      pendidikanTerakhir: [
        { value: "SD", text: "SD" },
        { value: "SMP", text: "SMP" },
        { value: "MTs", text: "MTs" },
        { value: "SMA", text: "SMA" },
        { value: "SMK", text: "SMK" },
        { value: "MA", text: "MA" },
        { value: "D-1", text: "D-1" },
        { value: "D-2", text: "D-2" },
        { value: "D-3", text: "D-3" },
        { value: "D-4", text: "D-4" },
        { value: "S-1", text: "S-1" },
        { value: "S-2", text: "S-2" },
        { value: "Profesi", text: "Profesi" },
        { value: "Kejar Paket C", text: "Kerjar Paket C" },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataProfil, null, 4);
    },
    isValid() {
      return !this.$v.dataProfil.$invalid;
    },
    isDirty() {
      return this.$v.dataProfil.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataProfil: {
      namaPelamar: {
        required,
      },
      posisiLamaran: {
        required,
      },
      nomorKTPPelamar: {
        required,
        minLength: minLength(16),
      },
      noHpPelamar: {
        required,
        numeric,
      },
      alamatPelamar: {
        required,
      },
      emailPelamar: {
        required,
        email,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataProfil[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    tgl() {
      if (this.dataProfil.tanggalLahirPelamar) {
        return moment(this.dataProfil.tanggalLahirPelamar).format("YYYY/MM/DD");
      } else {
        return "";
      }
    },
  },
  watch: {
    isValid: function (val) {
      this.$emit("alertProfil", val);
    },
  },
};
</script>
