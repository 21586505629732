<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <label for="">Apakah anda yakin akan menyimpan data ini</label><br />
        <b-alert :variant="variant" v-if="showing">{{ msg }}</b-alert>
        <b-button
          variant="primary"
          :disabled="!okeeee"
          @click="$bvModal.show('modal-daftar')"
          >Simpan</b-button
        >
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="modal-daftar"
        size="lg"
        centered
        title="Perhatian setelah dikirim data tidak bisa di Edit!!"
        header-bg-variant="warning"
        header-text-variant="light"
      >
        <h3 class="fw-bold">Pastikan Semua Data Sudah Terisi Dengan Benar!!</h3>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-daftar')">
            Batal
          </b-button>

          <b-button variant="primary" :disabled="busy" @click="create()">
            {{ button }}
          </b-button>
          <!-- <b-spinner v-else variant="primary" label="Spinning"></b-spinner> -->
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
export default {
  props: ["okeeee", "dataProfil"],
  data() {
    return {
      button: "Kirim",
      busy: false,
      moment,
      msg: "",
      variant: "",
      showing: false,
    };
  },
  methods: {
    create() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.loading = true;
      // console.log(this.dataProfil);
      let x = new Date();
      if (vm.dataProfil.statusKelengkapan == null) {
        axios
          .post(ipBackend + "kelengkapanLamaran/register", {
            namaPelamar: vm.dataProfil.namaPelamar,
            nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
            posisiLamaran: vm.dataProfil.posisiLamaran,
            tinggiBadanPelamar: vm.dataProfil.tinggiBadanPelamar,
            beratBadanPelamar: vm.dataProfil.beratBadanPelamar,
            agamaPelamar: vm.dataProfil.agamaPelamar,
            kebangsaanPelamar: vm.dataProfil.kebangsaanPelamar,
            jenisKelaminPelamar: vm.dataProfil.jenisKelaminPelamar,
            statusPelamar: vm.dataProfil.statusPelamar,
            tempatLahirPelamar: vm.dataProfil.tempatLahirPelamar,
            tanggalLahirPelamar: vm.dataProfil.tanggalLahirPelamar,
            noHpPelamar: vm.dataProfil.noHpPelamar,
            emailPelamar: vm.dataProfil.emailPelamar,
            alamatPelamar: vm.dataProfil.alamatPelamar,
            tanggalMasukLamaran: moment(x),
            nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
            postLokerId: vm.dataProfil.postLokerId,
            statusKelengkapan: 1,
            bulkPengalamanKerja: vm.dataProfil.bulkPengalamanKerja,
            bulkRiwayatPendidikan: vm.dataProfil.bulkRiwayatPendidikan,
          })
          .then((res) => {
            // console.log(res);
            if (res.data.message == "sukses") {
              vm.showing = true;
              vm.variant = "success";
              alert("BERHASIL MENGIRIM DATA KELENGKAPAN LAMARAN")
              setTimeout(() => {
                vm.busy = false;
                vm.$bvModal.hide("modal-daftar");
                vm.$router.push({ path: "/loginToken" });
              }, 2000);
            } else {
              vm.showing = true;
              vm.variant = "danger";
              vm.msg = res.data.message
              setTimeout(() => {
                vm.busy = false;
                vm.$bvModal.hide("modal-daftar");
                vm.$router.push({ path: "/loginToken" });
              }, 3000);
            }
          })
          .catch((err) => {
            // console.log(err);
            vm.showing = true;
            vm.variant = "danger";
            vm.msg = "TERJADI KESALAHAN PADA SERVER";
            setTimeout(() => {
              vm.$bvModal.hide("modal-daftar");
              vm.busy = false;

              vm.$router.push({ path: "/loginToken" });
            }, 4000);
          });
      } else {
        axios
          .post(ipBackend + "kelengkapanLamaran/updateAll", {
            id: vm.dataProfil.kelengkapanLamaranId,
            namaPelamar: vm.dataProfil.namaPelamar,
            nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
            posisiLamaran: vm.dataProfil.posisiLamaran,
            tinggiBadanPelamar: vm.dataProfil.tinggiBadanPelamar,
            beratBadanPelamar: vm.dataProfil.beratBadanPelamar,
            agamaPelamar: vm.dataProfil.agamaPelamar,
            kebangsaanPelamar: vm.dataProfil.kebangsaanPelamar,
            jenisKelaminPelamar: vm.dataProfil.jenisKelaminPelamar,
            statusPelamar: vm.dataProfil.statusPelamar,
            tempatLahirPelamar: vm.dataProfil.tempatLahirPelamar,
            tanggalLahirPelamar: vm.dataProfil.tanggalLahirPelamar,
            noHpPelamar: vm.dataProfil.noHpPelamar,
            emailPelamar: vm.dataProfil.emailPelamar,
            alamatPelamar: vm.dataProfil.alamatPelamar,
            tanggalMasukLamaran: vm.dataProfil.tanggalMasukLamaran,
            nomorKTPPelamar: vm.dataProfil.nomorKTPPelamar,
            postLokerId: vm.dataProfil.postLokerId,
            statusKelengkapan: 1,
            bulkPengalamanKerja: vm.dataProfil.bulkPengalamanKerja,
            bulkRiwayatPendidikan: vm.dataProfil.bulkRiwayatPendidikan,
          })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.showing = true;
              vm.variant = "success";
              vm.msg = "BERHASIL MENGIRIM DATA KELENGKAPAN LAMARAN";
              setTimeout(() => {
                vm.$bvModal.hide("modal-daftar");
                vm.busy = false;
                vm.$router.push({ path: "/loginToken" });
              }, 4000);
              // console.log(res, "<<>>");
            } else {
              vm.showing = true;
              vm.variant = "danger";
              vm.msg = res.data.message;
              // console.log(res.data.message);
              vm.busy = false;
            }
          })
          .catch((err) => {
            // console.log(err);
            vm.showing = true;
            vm.variant = "danger";
            vm.msg = "TERJADI KESALAHAN PADA SERVER";
          });
      }
    },
  },
  watch: {
    okeeee: function (val) {
      // console.log(val);
    },
  },
};
</script>
