<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-form-group label="Nama Perusahaan" label-cols-md="3">
            <b-form-input
              v-model="$v.dataPengalaman.namaPerusahaan.$model"
              type="text"
              @keydown.enter.prevent="addPengalaman"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Jabatan" label-cols-md="3">
            <b-form-input
              v-model="$v.dataPengalaman.jabatan.$model"
              type="text"
              @keydown.enter.prevent="addPengalaman"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <b-button
              @click="addPengalaman"
              variant="primary"
              :disabled="!isValid"
              >Tambahkan</b-button
            >
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="3">
        <b-form-group
          label="Per Halaman"
          label-for="per-page-select"
          label-cols-md="6"
          label-align-md="left"
          label-size="md"
          style="background-color: "
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="6" offset-md="3">
        <b-form-group
          label="Cari"
          label-for="filter-input"
          label-cols-md="3"
          label-align-md="right"
          label-size="md"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Ketik disini untuk mencari ..."
            ></b-form-input>

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
                variant="danger"
                >Hapus</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-table
          :items="listPengalaman"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          stacked="md"
          responsive
          show-empty
          small
          @filtered="onFiltered"
          bordered
          striped
          hover
          :busy="tableBusy"
        >
          <template #cell(no)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(actions)="row">
            <b-button
              variant="danger"
              class="mr-1"
              v-c-tooltip.hover.click="'Hapus'"
              @click="deleteItem(row.index)"
              ><CIcon name="cil-trash" /> {{ row.actions }}</b-button
            >
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="5" offset-md="7">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "inputTabProfil",
  props: ["listPengalaman"],
  data() {
    return {
      dataPengalaman: {
        namaPerusahaan: "",
        jabatan: "",
      },
      pengalaman: {},
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaPerusahaan",
          label: "Perusahaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jabatan",
          label: "Jabatan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.dataPengalaman, null, 4);
    },
    isValid() {
      return !this.$v.dataPengalaman.$invalid;
    },
    isDirty() {
      return this.$v.dataPengalaman.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPengalaman: {
      namaPerusahaan: {
        required,
      },
      jabatan: {
        required,
      },
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    reset() {
      let vm = this;
      vm.dataPengalaman.namaPerusahaan = "";
      vm.dataPengalaman.jabatan = "";
    },
    addPengalaman() {
      let vm = this;
      if (vm.isValid) {
        let penampung = {};
        penampung.namaPerusahaan = vm.dataPengalaman.namaPerusahaan;
        penampung.jabatan = vm.dataPengalaman.jabatan;
        vm.listPengalaman.push(penampung);
        // vm.items.forEach((element, index) => {
        //   vm.items[index].no = index + 1;
        // });
        vm.reset();
        vm.pengalaman.data = vm.listPengalaman;
        vm.$emit("alertPengalaman", vm.pengalaman);
      }
    },
    deleteItem(index) {
      this.listPengalaman.splice(index, 1);
      this.$emit("alertPengalaman", this.pengalaman);
    },
  },
};
</script>
