<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-alert dismissible fade :show="show" :variant="variant">{{
          msg
        }}</b-alert>
        <b-table
          :items="kebutuhanPelamar"
          :fields="fields"
          responsive
          bordered
          striped
          hover
        >
          <template #cell(file1)="item">
            <!-- <b-button
              variant="primary"
              class="mr-1"
              @click="
                selectFile(),idx = item.index,namaKebutuhan = item.item.namaKebutuhan"
              >Upload File</b-button
            > -->
            <b-button
              style="margin-left: 10px"
              v-if="item.item.statusKebutuhan != 0"
              variant="dark"
              @click="view(item.item)"
              >Lihat File</b-button
            >
          </template>
          <template #cell(statusKebutuhan)="item">
            <center>
              <b-form-checkbox
                v-model="item.item.statusKebutuhan"
                value="1"
                disabled
                unchecked-value="0"
                @change="ngirim()"
              >
              </b-form-checkbox>
            </center>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <div>
      <input
        class="inputan"
        type="file"
        id="file1"
        ref="file1"
        @input="handleFile()"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  name: "inputTabProfil",
  props: ["kebutuhanPelamar"],
  data() {
    return {
      file1: "",
      idx: "",
      namaKebutuhan:"",
      show: false,
      variant: "",
      msg: "",
      fields: [
        {
          key: "statusKebutuhan",
          label: "Checklist",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaKebutuhan",
          label: "Nama Lampiran",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "file1",
          label: "Upload File",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
    };
  },
  computed: {
    komplit() {
      let x = 0;
      this.kebutuhanPelamar.forEach((item) => {
        if (item.statusKebutuhan == 1) {
          x++;
        }
      });
      return x;
    },
  },
  methods: {
    ngirim() {
      let vm = this;
      if (this.komplit == this.kebutuhanPelamar.length) {
        vm.$emit("alertLampiran", true);
      } else {
        vm.$emit("alertLampiran", false);
      }
    },
    handleFile() {
      let vm = this;
      vm.file1 = vm.$refs.file1.files[0];
      vm.kirimFile(vm.indexUpload);
    },
    selectFile() {
      let fileInputElement = this.$refs.file1;
      fileInputElement.click();
    },
    view(x) {
      // console.log(x)
      if(!x.file1){
        window.open(`${ipBackend}asset/pdf/${x.fileKebutuhan}`);
      }else{
        window.open(`${ipBackend}asset/pdf/${x.file1}`);
      }
      
    },
    kirimFile() {
      let vm = this;
      let formData = new FormData();
      formData.append(
        "kebutuhanPelamarId",
        vm.kebutuhanPelamar[vm.idx].kebutuhanPelamarId
      );
      formData.append("file1", vm.file1);
      axios
        .post(ipBackend + "kelengkapanLamaran/updateFile", formData)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.loading = false;
            vm.kebutuhanPelamar[vm.idx].statusKebutuhan = 1;
            vm.kebutuhanPelamar[vm.idx].file1 = res.data.data;
            vm.msg = `UPLOAD DATA LAMPIRAN ${vm.namaKebutuhan} BERHASIL`;
            vm.variant = "success";
            vm.show = true;
            setTimeout(() => {
              vm.show = false;
            }, 4000);
            this.ngirim()
          } else {
            vm.loading = false;
            vm.msg = toUpperCase(res.data.message);
            vm.variant = "danger";
            vm.show = true;
            setTimeout(() => {
              vm.show = false;
            }, 4000);
          }
        })
        .catch((err) => {
          // console.log(err);
          vm.msg = toUpperCase(err);
          variant = "danger";
          vm.show = true;
          setTimeout(() => {
            vm.show = false;
          }, 4000);
        });
    },
  },
  watch: {
    kebutuhanPelamar: function (val) {
      // console.log(val)
      this.ngirim();
    },
  },
};
</script>
<style scoped>
.inputan {
  visibility: hidden;
}
</style>
