<template>
  <div>
    <!-- <section class="bg-dark">
      <b-container>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <div
              style="
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <h4 class="mt-0 mb-0"><strong>LOKER SEROVA</strong></h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section> -->

    <section style="padding: 60px 0">
      <b-container>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <h4 class="text-center">
              <strong>FORMULIR KELENGKAPAN PENDAFTARAN LOKER</strong>
            </h4>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col cols="12" md="12" lg="12">
            <b-card>
              <div>
                <b-tabs content-class="mt-3 mb-0" align="center">
                  <b-tab title="PROFIL PELAMAR" active>
                    <input-tab-profil
                      :dataProfil="dataProfil"
                      @alertProfil="fromProfil($event)"
                    />
                  </b-tab>
                  <b-tab title="RIWAYAT PENDIDIKAN">
                    <input-tab-pendidikan
                      :listRiwayat="listRiwayat"
                      :dataRiwayat="dataRiwayat"
                      @alertPendidikan="fromRiwayat($event)"
                    />
                  </b-tab>
                  <b-tab title="PENGALAMAN KERJA">
                    <input-tab-kerja
                      :listPengalaman="listPengalaman"
                      @alertPengalaman="fromPengalaman($event)"
                    />
                  </b-tab>
                  <b-tab title="DOKUMEN LAMPIRAN">
                    <input-tab-lampiran
                      :kebutuhanPelamar="kebutuhanPelamar"
                      @alertLampiran="fromLampiran($event)"
                    />
                  </b-tab>
                  <b-tab title="SIMPAN DATA"
                    ><input-tab-simpan
                      :okeeee="okeeee"
                      :dataProfil="dataProfil"
                  /></b-tab>
                </b-tabs>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import inputTabProfil from "../component/hcms/dokumenLoker/inputTabProfil.vue";
import inputTabKerja from "../component/hcms/dokumenLoker/inputTabKerja.vue";
import inputTabPendidikan from "../component/hcms/dokumenLoker/inputTabPendidikan.vue";
import inputTabLampiran from "../component/hcms/dokumenLoker/inputTabLampiran.vue";
import inputTabSimpan from "../component/hcms/dokumenLoker/inputTabSimpan.vue";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    inputTabProfil,
    inputTabKerja,
    inputTabPendidikan,
    inputTabLampiran,
    inputTabSimpan,
  },
  data() {
    return {
      joss: false,
      dataProfil: {
        namaPelamar: "",
        nomorKTPPelamar: "",
        tinggiBadanPelamar: "",
        posisiLamaran: "",
        beratBadanPelamar: "",
        agamaPelamar: "",
        kebangsaanPelamar: "",
        jenisKelaminPelamar: "",
        statusPelamar: "",
        tempatLahirPelamar: "",
        tanggalLahirPelamar: "",
        noHpPelamar: "",
        emailPelamar: "",
        alamatPelamar: "",
        tanggalMasukLamaran: "",
        postLokerId: "",
        statusPostLoker: 0,
        bulkRiwayatPendidikan: [],
        bulkPengalamanKerja: [],
        dataLampiran: false,
        validasi: false,
      },
      dataRiwayat: [],
      dataPengalaman: [],
      listRiwayat: [],
      listPengalaman: [],
      kebutuhanPelamar: [],
      okeeee: false,
    };
  },
  created() {
    let vm = this;
    vm.dataProfil.postLokerId = vm.$route.params.postLokerId;
    vm.getKebutuhan();
    vm.getDetail();
  },
  methods: {
    fromProfil(data) {
      this.dataProfil.validasi = data;
      // this.joss = !this.joss;
      console.log(data, 'profil')
      this.okesip();
    },
    fromRiwayat(data) {
      this.dataProfil.bulkRiwayatPendidikan = data.data;
      // this.joss = !this.joss;
      console.log(data,'riwayat')
      this.okesip();
    },
    fromPengalaman(data) {
      this.dataProfil.bulkPengalamanKerja = data.data;
      // this.joss = !this.joss;
      console.log(data,'pengalaman')
      this.okesip();
    },
    fromLampiran(data) {
      this.dataProfil.dataLampiran = data;
      // this.joss = !this.joss;
      console.log(data,'lampiran')
      this.okesip();
    },
    okesip() {
      let vm = this;
      let x = vm.dataProfil;
      if (x.validasi == true) {
        if (x.dataLampiran == true) {
          if (x.bulkRiwayatPendidikan.length > 0) {
            vm.okeeee = true
            // if (x.bulkPengalamanKerja.length > 0) {
            //   vm.okeeee = true;
            // } else {
            //   vm.okeeee = false;
            // }
          } else {
            vm.okeeee = false;
          }
        } else {
          vm.okeeee = false;
        }
      } else {
        vm.okeeee = false;
      }
    },
    async getKebutuhan() {
      let vm = this;
      let listKebutuhan = await axios.get(
        ipBackend +
          "postLoker/listKebutuhanByLokerId/" +
          vm.dataProfil.postLokerId
      );
      // console.log(listKebutuhan)
      vm.kebutuhanPelamar = listKebutuhan.data.data.map((item) => {
        if (item.statusKebutuhan == 0) {
          vm.dataProfil.dataLampiran = false;
        }
        return {
          ...item,
          file1: "",
          holder: item.fileKebutuhan,
        };
      });
    },
    getDetail() {
      let vm = this;
      axios
        .get(
          ipBackend +
            "postLoker/detailListByStatus/" +
            vm.dataProfil.postLokerId
        )
        .then((res) => {
          console.log(res.data.data,'respon loker')
          vm.dataProfil = res.data.data[0];
          vm.listRiwayat = res.data.data[0].riwayatPendidikan;
          vm.listPengalaman = res.data.data[0].pengalamanKerja;
          vm.dataProfil.bulkRiwayatPendidikan =
            res.data.data[0].riwayatPendidikan;
          vm.dataProfil.bulkPengalamanKerja = res.data.data[0].pengalamanKerja;
          vm.kebutuhanPelamar = res.data.data[0].kebutuhanPelamar;
          vm.dataProfil.tanggalLahirPelamar = res.data.data[0].tanggalLahirPengirim
          vm.dataProfil.tglLahir = moment(
            vm.dataProfil.tanggalLahirPengirim
          ).format("LL");
          vm.dataProfil.tglMasuk = moment(
            vm.dataProfil.tanggalMasukLamaran
          ).format("LL");
          vm.dataProfil.namaPelamar = vm.dataProfil.namaPengirim;
          vm.dataProfil.emailPelamar = vm.dataProfil.emailPengirim;
          vm.dataProfil.posisiLamaran = vm.dataProfil.namaPosisi;
          vm.dataProfil.alamatPelamar = vm.dataProfil.alamatPengirim;
          vm.dataProfil.nomorKTPPelamar = vm.dataProfil.nomorKTPPengirim;
          vm.dataProfil.noHpPelamar = res.data.data[0].nomorTeleponPengirim;
          vm.dataProfil.validasi = false;
          vm.dataProfil.dataLampiran = false;

          console.log(vm.dataProfil)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
